import React, { useEffect, useState } from 'react';
import Header from './data/header';
import Footer from './data/footer';
import Home from './data/body';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome arrow icon
import ChatWidget from './data/chat';
import { ClipLoader } from 'react-spinners'; // Import a spinner component

const App = () => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [showArrowUp, setShowArrowUp] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Get the scroll position and the height of the document
      const scrollPosition = window.innerHeight + window.scrollY;
      const pageHeight = document.documentElement.offsetHeight;

      // Check if the user is at the bottom of the page (within 50px)
      if (scrollPosition >= pageHeight - 50) {
        setIsAtBottom(true); // Set state to indicate user is at the bottom
        setShowArrowUp(true); // Show arrow up when at the bottom
      } else {
        setIsAtBottom(false); // Not at the bottom
        setShowArrowUp(false); // Hide arrow when scrolling up
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Simulate loading for 3 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f5f5f5', // Light gray background
        }}
      >
        <ClipLoader size={150} color={'#0132F3'} />
      </div>
    );
  }

  return (
    <>
      <div>
        <Header />
      </div>
      <Home />
      <Footer />

      {/* Conditionally render the ChatWidget if not at the bottom */}
      {!isAtBottom && <ChatWidget />}

      {/* Show the "arrow up" button when the user scrolls to the bottom */}
      {showArrowUp && (
        <div
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            bottom: '30px',
            right: '30px',
            height: "2rem",
            width: "2rem",
            display: "grid",
            placeItems: "center",
            backgroundColor: '#fff', // White background
            color: '#0132F3', // Black arrow color
            borderRadius: '50%', // 50% border radius for a circular button
            padding: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            zIndex: 1000, // Ensure it's on top of other elements
          }}
        >
          <FontAwesomeIcon icon={faArrowUp} size="lg" />
        </div>
      )}
    </>
  );
};

export default App;
